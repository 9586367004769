
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { BraceletDetail } from '../../types/bracelet'

@Component({
  filters: {
    filtersDeviceStatus (value: string) {
      return value === '1' ? '在线' : value === '2' ? '离线' : ''
    },
    filtersPresentStatus (value: string) {
      return value === '0' ? '在岗' : value === '1' ? '脱岗' : ''
    },
    filtersSosStatus (value: string) {
      return value === '0' ? '正常' : value === '1' ? '求助' : ''
    },
    filtersFallState (value: string) {
      return value === '0' ? '否' : value === '1' ? '是' : ''
    }
  }
})
export default class WorkCardDetailList extends Vue {
  private isShowDialog = false
  private tableData = {
    loading: false,
    tr: [
      {
        label: '姓名',
        prop: 'workerName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '关联设备',
        prop: 'relevance',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '在岗监测',
        prop: 'presentStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: 'SOS',
        prop: 'sosState',
        minWidth: '130',
        showOverflowTooltip: true
      },
      {
        label: '是否跌倒',
        prop: 'fallState',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '设备状态',
        prop: 'deviceStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备电量',
        prop: 'battery',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '数据上报时间',
        prop: 'collectTime',
        minWidth: '150',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private info = {
    projectId: this.projectId,
    deviceStatus: '', // 设备状态 1在线 2离线
    sosStatus: '', // sos状态 0正常 1求助
    presentStatus: '', // 在岗状态 0 在岗 1 脱岗
    workerName: '', // 工人姓名
    isDevice: '', // 绑定状态 0 未绑定 1已绑定
    deviceNumber: ''
  }

  private workerInfo = {
    workerId: '',
    watchDeviceId: '',
    deviceId: '',
    workerName: ''
  }

  private workerDeviceId = ''// 设备绑定工人id

  private workerRules = {
    watchDeviceId: [
      { required: true, message: '请选择设备串号', trigger: ['blur', 'change'] }
    ]
  }

  private deviceList: {deviceId: string; deviceNumber: string}[] = []

  private page = 1
  private size = 10
  private total = 0

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  created () {
    this.getData()
    this.getDeviceList()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.workcard.selectWorkCardPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  getDeviceList () {
    this.$axios.get(this.$apis.workcard.selectWatchListUnBindlist, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 离线行置灰
  setOfflineRow (list: {row: any; column: any; rowIndex: number}) {
    if (list.column.property === 'deviceStatus') {
      if (list.row.deviceStatus !== '1') {
        return { color: '#b7b7b7' }
      }
      return { color: '#00CCAE' }
    }
  }

  // 轨迹回放
  onPlayback (id: string) {
    this.$router.push({
      name: 'workcardListPlayback',
      params: { workerId: id, projectId: this.projectId }
    })
  }

  // sos解除
  cancelSos (id: string) {
    this.$confirm('是否解除SOS报警?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.workcard.updateCancelSos, {
        workerId: id
      }).then(() => {
        this.$message({
          message: '解除成功',
          type: 'success'
        })
        this.getData()
      })
    }).catch(() => {
      // do...
    })
  }

  // 设备绑定
  onDevice (row: BraceletDetail) {
    if (row.deviceId && row.deviceNumber) {
      this.deviceList.push({
        deviceId: row.deviceId,
        deviceNumber: row.deviceNumber
      })
    }
    this.isShowDialog = true
    this.workerDeviceId = row.deviceId || ''
    this.workerInfo = {
      workerId: row.workerId,
      watchDeviceId: this.workerDeviceId,
      deviceId: row.deviceId || '',
      workerName: row.workerName
    }
  }

  // 设备绑定提交
  onSubmit () {
    (this.$refs.workerInfo as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          workerId: this.workerInfo.workerId,
          watchDeviceId: this.workerInfo.watchDeviceId
        }
        this.$axios.post(this.$apis.workcard.updateBindWorkCard, info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.isShowDialog = false
          this.clear()
          this.getData()
        })
      }
    })
  }

  // 设备解绑
  onUntie () {
    const info = {
      workerId: this.workerInfo.workerId
    }
    this.$axios.post(this.$apis.workcard.updateUnBindWorkCard, info).then(() => {
      this.$message({
        message: '解绑成功',
        type: 'success'
      })
      this.isShowDialog = false
      this.clear()
      this.getData()
    })
  }

  // 绑定设备弹窗关闭
  close () {
    this.isShowDialog = false
    this.clear()
    this.getDeviceList()
  }

  // 清除弹窗内容
  clear () {
    this.workerInfo = {
      workerId: '',
      watchDeviceId: '',
      deviceId: '',
      workerName: ''
    }
    this.$nextTick(() => {
      (this.$refs.workerInfo as ElForm).clearValidate()
    })
  }
}
